import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Section from '../components/layout/Section';

type IndexProps = {
  data: IndexQueryData
};
export default function Index({ data }:IndexProps) {
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>{data.site.siteMetadata.name}</title>
    </Helmet>
    <Section id="support-articles">
      <h1>Cro Metrics Support Articles</h1>
      <ul>
        {data.allMarkdownRemark.edges.map(({ node })=>{
          const { slug, title } = node.frontmatter;
          return <li key={slug}><Link to={slug}>{title}</Link></li>;
        })}
      </ul>
    </Section>
  </>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
  allMarkdownRemark: {
    edges: [
      {
        node: {
          frontmatter: {
            title:string,
            slug:string,
          }
        }
      }
    ],
  }
};
export const query = graphql`
  query IndexQuery{
    site {
      siteMetadata {
        name
        description
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/articles/"}}) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;
